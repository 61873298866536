.App {
  height: 100vh;
}

a, .btn-linka {
  color: #f8f9fa;
  /* text-light */
}

.App .btn-outline-primary:hover {
  color: unset;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

#loadingOverlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  align-items: center;
  display: flex;

  visibility: hidden;
  opacity: 0;
  transition: none;
}

.App {
  filter: none;
  transition: none;
}

#loadingOverlay.loading + #root {
  filter: blur(20px);
}

#loadingOverlay.loading {
  visibility: visible;
  opacity: 0.2;
  transition: opacity .2s ease-in-out, visibility 0s ease-in-out 0s;
}

#loadingOverlay.loading.initial {
  visibility: visible;
  opacity: 1;
  transition: filter .2s ease-in-out, 0s;
}

.loadingSpinner {
  margin: 0 auto;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media(max-width: 550px) {
  .small-mobile {
    font-size: 80%;
  }
  .justify-content-center-mobile {
    justify-content: space-around;
  }
  #initialCard {
    margin-top: 30px;
  }
}

@media(min-width: 550px) {
  #initialCard {
    position: relative;
    top: 35%;
    transform: translateY(-35%);
  }
}

#locale-selector {
  position: absolute;
  bottom: 24px;
  right: 30px;
}

@media(max-width: 400px) {
  .hide-mobile {
    display: none;
  }
  #locale-selector {
    bottom: 12px;
    right: 15px;
  }
}

#logo-full {
  display: block;
}

#logo-short {
  display: none;
}

@media(max-width: 576px) {
  #logo-full {
    display: none;
  }
  #logo-short {
    display: block;
  }
}

.input-field {
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-radius: 0 0 0 5px;
}

.input-field:focus {
  background-color: rgba(0, 0, 0, 0.2);
  border-top: none;
  border-right: none;
}

.hover-bg-white:hover {
  background-color: white;
  border-color: white;
}

#bottom-bar-spacer {
  height: 12vh;
}

.hover-opacity:hover {
  opacity: 0.9;
}

.accordion .card .btn.collapsed .minus-collapse {
  display: none;
}

.accordion .card .btn:not(.collapsed) .plus-collapse {
  display: none;
}

/* Firefox scrollbar */
html * {
  scrollbar-color:rgba(128,128,128, 0.5) rgba(0,0,0, 0.3);
  scrollbar-width: thin;
}

/* Chrome scrollbar */
::-webkit-scrollbar { width: 6px; height: 3px; }
::-webkit-scrollbar-button { display: none; }
::-webkit-scrollbar-track { background-color: rgba(0,0,0, 0.3); }
::-webkit-scrollbar-thumb { height: 50px; background-color: rgba(128, 128, 128, 0.5); border-radius: 3px; }


.hover-zoom {
  transition: transform .1s;
}
.hover-zoom:hover {
  position: relative;
  z-index: 9999;
  transform: translateX(-120%) translateY(-20%)  scale(3.5);
}

.hover-zoom-down {
  transition: transform .1s;
}
.hover-zoom-down:hover {
  z-index: 9999;
  transform: translateX(-120%) translateY(90%) scale(3.5);
}