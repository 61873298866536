@font-face {
  font-family: Gotham;
  src: url(Gotham-Light.ttf) format("truetype");
  font-weight: 100;
  font-style: normal
}

@font-face {
  font-family: Gotham;
  src: url(Gotham-Medium.ttf) format("truetype");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: Gotham;
  src: url(Gotham-Bold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal
}
