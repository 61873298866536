@font-face {
    font-family: "Assistant";
    src: url("Assistant-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Assistant";
    src: url("Assistant-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Assistant";
    src: url("Assistant-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }