@font-face {
    font-family: "DavidLibre";
    src: url("DavidLibre-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "DavidLibre";
    src: url("DavidLibre-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "DavidLibre";
    src: url("DavidLibre-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }