.Resizer {
  opacity: .7;
  z-index: 1;
  box-sizing: border-box;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.Resizer.vertical {
  width: 6px;
  margin: 0 -4px;
  border-left: 3px solid rgba(0, 0, 0, 0);
  border-right: 3px solid rgba(0, 0, 0, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 3px solid rgba(200, 200, 200, 0.5);
  border-right: 3px solid rgba(200, 200, 200, 0.5);
}

.editBar.nav-tabs {
  border: none;
}

.editBar.nav-tabs .nav-link.active {
  font-weight: bold;
}

.editBar.nav-tabs .nav-link {
  border: none;
  margin-bottom: 0 !important;
}

#svgStage>svg {
  margin-top: -2px;
  margin-left: -2px;
}
