html, body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

code, .code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  white-space: pre-wrap;
}

.of-y-auto {
  overflow-y: auto;
}

.of-y-scroll {
  overflow-y: scroll;
}

.of-x-hidden {
  overflow-x: hidden;
}

.h-100vh {
  height: 100vh;
}

.center-trans {
  margin-left: 50%;
  margin-top: 50%;
  transform: translate(-50%, -50%);
}

.btn.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 0.75;
}

.btn.btn-circle-sm {
  width: 20px;
  height: 20px;
  padding: 3px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 10px;
  line-height: 0.75;
}

.border-primary {
  border-style: solid;
  border-width: 3px;
  border-radius: 5px;
}

#root .border-dark {
  border-color: rgba(255, 255, 255, 0.4) !important;
  border-style: solid;
  border-width: 3px;
  border-radius: 5px;
}

.border-invisible {
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 3px;
  border-radius: 5px;
}

.stretch-contain {
  transform: rotate(0);
}

input[type="number"].hide-arrows::-webkit-outer-spin-button,
input[type="number"].hide-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"].hide-arrows {
  -moz-appearance: textfield;
}

.email-link {
  color: #00bc70; /* Establece el color del texto a verde */
  text-decoration: underline; /* Subraya el texto */
}

.email-link:hover {
  color: #009c50; /* Cambia el color cuando se pasa el cursor por encima */
}