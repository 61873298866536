// Override default variables before the import
// @import '../node_modules/bootstrap/scss/variables.scss';

/*
$gray-800: -dark
$gray-700: .nav-link .active
$gray-600: -secondary
$gray-100: -light

$link-color: $primary
*/

$dark: #1c1f2b;
$secondary: #1c1f2b;
$primary: #00bc70;

/*
Can be used in CSS with var(--primary) ...
*/
.App, .bg-color {
  background-color: #1c1f2b;
}

.App a.text-light:hover, #root button.text-light:hover, .hover-primary:hover {
  color: #00bc70 !important;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
