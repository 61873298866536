@font-face {
    font-family: "Almarai";
    src: url("Almarai-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Amiri";
    src: url("Amiri-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Cairo";
    src: url("Cairo-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Mada";
    src: url("Mada-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Lateef";
    src: url("Lateef.ttf") format("truetype");
}

@font-face {
    font-family: "Arabic Typesetting";
    src: url("ArabicTypesetting.ttf") format("truetype");
}