@font-face {
    font-family: 'Alef';
    src: url('Alef-bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'Alef';
    src: url('Alef-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;

}